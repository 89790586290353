import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import VippsButton from "../../VippsButton";
import PriceBar from "../CheckoutCardOneTime/PriceBar";

interface CheckoutCardProps {
  price: number;
  onSubmit: () => void;
  discount?: number;
  needLogin?: boolean;
}

const CheckoutCard: React.FC<CheckoutCardProps> = ({
  price,
  discount,
  onSubmit,
  needLogin,
}) => {
  const showDiscount = !!(discount && discount > 0);

  return (
    <Box
      sx={{
        width: "90%",
        height: "100%",
        borderRadius: 2,
        backgroundColor: "#F0F1F3",
        padding: 2,
      }}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        height={"100%"}
        gap={2}
      >
        <Stack direction={"column"} height={"100%"} gap={1}>
          <PriceBar text={"Pris"} price={price} negative={false} />
          {showDiscount && (
            <PriceBar text={"Rabatt"} price={discount ?? 0} negative={true} />
          )}
          <Divider orientation="horizontal" flexItem />
          <PriceBar
            text={"Total"}
            price={price - (discount ?? 0)}
            negative={false}
          />
        </Stack>
        {needLogin && (
          <Typography
            variant={"body2"}
            color={"text.secondary"}
            textAlign={"center"}
          >
            For å fullføre kjøpet må du først logge inn:
          </Typography>
        )}
        <Stack direction={"row"} justifyContent={"center"} width={"100%"}>
          <VippsButton
            customText={needLogin ? "Logg inn med" : undefined}
            onClick={onSubmit}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CheckoutCard;
