import matematikkR1 from "../../assets/matematikk-r1.png";
import matematikkS1 from "../../assets/matematikk-s1.png";
import matematikk1P from "../../assets/matematikk-1p.png";
import matematikk2P from "../../assets/matematikk-2p.png";
import matematikk1T from "../../assets/matematikk-1t.png";

export const courseIdToImages: Record<string, string> = {
  "matematikk-r1": matematikkR1,
  "matematikk-r2": matematikkR1,
  "matematikk-s1": matematikkS1,
  "matematikk-s2": matematikkS1,
  "matematikk-1p": matematikk1P,
  "matematikk-2p": matematikk2P,
  "matematikk-1t": matematikk1T,
};
