import { Stack } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import {
  anonymousUser,
  internalServerError,
  realfagsPortalenBaseUrl,
  realfagsPortalenIntegrationBaseUrl,
} from "../constants";
import { getIsMobile } from "../utils/helpers";
import { Mixpanel } from "../utils/mixpanel";
import "./App.css";
import RealfagsPortalenLogo from "./RealfagsportalenLogo";
import vipps from "./vippsLogo.png";
import AgreementCheckoutPage, { Access, Price } from "./AgreementCheckoutPage";
import { getCoursePriceAndDiscount } from "./initiatePayment";

const baseUrl = "https://vipps.realfagsportalen.no/";

export const realfagsportalenUrl =
  "https://realfagsportalen-api-srzpr47s3q-lz.a.run.app/";

function InitiateAgreement() {
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [price, setPrice] = useState<Price>();
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const [access, setAccess] = useState<Access>("Abonnement");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const coursePath = urlParams.get("coursePath");
  const courseIds = coursePath?.split("course/");
  const courseId =
    courseIds?.length === 2 ? courseIds[1] : "Course id not found";
  const email = urlParams.get("email") ?? undefined;
  const urlEnding = access === "Abonnement" ? "initiate-agreement" : "initiate";

  var data = JSON.stringify({
    email,
    courseId,
  });

  useEffect(() => {
    Mixpanel.identify(email ?? anonymousUser);
    Mixpanel.track("Initiate agreement page entered", {
      courseId,
      email: email ?? "Not logged in",
      isMobile: getIsMobile(window.navigator.userAgent),
    });
  }, []);

  useEffect(() => {
    if (!courseId) {
      return;
    }
    getCoursePriceAndDiscount(courseId, email).then((res) => {
      setPrice(res.price);
    });
  }, []);

  useEffect(() => {
    if (!courseId) {
      setError("Mangler kurs id");
      return;
    }
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data,
    };
    if (!paymentConfirmed) {
      return;
    }
    axios(`${realfagsportalenUrl}payment/${urlEnding}`, options)
      .then((res) => {
        const paymentUrl = res.data.url;
        setPaymentUrl(paymentUrl);
      })
      .catch((err) => {
        const errResponse = err.response;
        if (
          errResponse &&
          errResponse.status === 400 &&
          errResponse.data &&
          errResponse.data.message
        ) {
          setError(errResponse.data.message);
        } else {
          setError(internalServerError);
        }
      });
  }, [paymentConfirmed]);

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);
  const informationOnError = (
    <p>
      <p>{error}</p>
      {"Trykk "}
      <u
        onClick={() => {
          window.location.href = `https://www.realfagsportalen.no/`;
        }}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til forsiden."}
    </p>
  );
  const displayMessage = error ? (
    informationOnError
  ) : (
    <p>Henter kursinformasjon...</p>
  );
  const isMobile = getIsMobile(window.navigator.userAgent);
  if (courseId && price && !paymentConfirmed) {
    return (
      <Stack
        width="100%"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#d2cafb",
        }}
      >
        <AgreementCheckoutPage
          courseId={courseId}
          email={email}
          onSubmit={() => {
            if (email) {
              Mixpanel.identify(email);
              Mixpanel.track("Agreement with vipps pressed", {
                courseId,
                email,
              });
              setPaymentConfirmed(true);
            } else {
              Mixpanel.identify(anonymousUser);
              Mixpanel.track("Login from payment pressed", {
                courseId,
                anonymousUser,
              });
              window.location.href = `${baseUrl}?redirectUrl=${window.location.href}&paymentLearnWorldsCourseId=${courseId}`;
            }
          }}
          price={price}
          onAccessChange={(newValue: string) => {
            setAccess(newValue === "Abonnement" ? "Abonnement" : "Engangskjøp");
          }}
          access={access}
        />
      </Stack>
    );
  }
  return (
    <div className="App">
      <RealfagsPortalenLogo isMobile={isMobile} />
      {!error && (
        <div style={{ alignItems: "center" }}>
          <MutatingDots
            width="80"
            color="#ffd43b"
            secondaryColor="#ffd43b"
            ariaLabel="three-dots-loading"
          />
        </div>
      )}
      {displayMessage}
    </div>
  );
}

export default InitiateAgreement;
