import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface PriceBarProps {
  text: string;
  price: number;
  negative: boolean;
}

const PriceBar: React.FC<PriceBarProps> = ({ text, price, negative }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
      <Stack direction={"row"}>
        <Typography variant="h6" fontWeight={"bold"}>
          {text}
        </Typography>
      </Stack>
      <Stack direction={"row"} width={"30%"} justifyContent={"flex-end"}>
        <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
          {negative ? "-" : ""}
          {price} kr
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PriceBar;
