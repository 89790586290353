import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { textSpanContainsPosition } from "typescript";

interface SliderProps {
  onValueChange: (value: string) => void;
  leftValue: string;
  rightValue: string;
  defaultValue: string;
}

const Slider: React.FC<SliderProps> = ({
  onValueChange,
  leftValue,
  rightValue,
  defaultValue,
}) => {
  const [value, setValue] = React.useState<string>(defaultValue);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    onValueChange(newValue);
  };

  const styleObjActive = {
    paddingY: 0.3,
    paddingX: 1,
    textTransform: "none",
    backgroundColor: "#FFD43B",
    color: "#001C40",
    borderRadius: 2,
    "&:hover": {
      cursor: "default",
      backgroundColor: "#FFD43B",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFD43B",
      textColor: "#001C40",
    },
    fontWeight: "semibold",
    fontSize: 18,
    height: "30px",
  };

  const styleObjNonActive = {
    paddingY: 0.3,
    paddingX: 1,
    color: "white",
    textTransform: "none",
    fontWeight: "semibold",
    fontSize: 18,
    height: "30px",
  };

  if (value === rightValue) {
    return (
      <Stack
        direction="row"
        sx={{ backgroundColor: "#001C40", borderRadius: 3.7 }}
        gap={1}
        paddingX={0.8}
        paddingY={0.8}
        alignItems={"center"}
      >
        <Button
          variant="text"
          sx={styleObjNonActive}
          onClick={() => handleChange(leftValue)}
        >
          {leftValue}
        </Button>
        <Button variant="contained" sx={styleObjActive}>
          {rightValue}
        </Button>
      </Stack>
    );
  }
  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: "#001C40", borderRadius: 3.7 }}
      gap={1}
      paddingX={0.8}
      paddingY={0.8}
      alignItems={"center"}
    >
      <Button variant="contained" sx={styleObjActive}>
        {leftValue}
      </Button>

      <Button
        variant="text"
        sx={styleObjNonActive}
        onClick={() => handleChange(rightValue)}
      >
        {rightValue}
      </Button>
    </Stack>
  );
};

export default Slider;
