import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import {
  anonymousUser,
  internalServerError,
  realfagsPortalenRedirectUrl,
} from "../constants";
import "./App.css";
import { getIsMobile } from "../utils/helpers";
import RealfagsPortalenLogo from "./RealfagsportalenLogo";
import { Mixpanel } from "../utils/mixpanel";
import { realfagsportalenUrl } from "./initiatePayment";

const baseUrl = "https://vipps.realfagsportalen.no/";

function AfterLogin() {
  const [loginUrl, setLoginUrl] = useState<string>("");
  const [paymentLearnWorldsCourseId, setPaymentLearnWorldsCourseId] = useState<
    string | undefined
  >();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const redirectUrl =
    window.localStorage.getItem(realfagsPortalenRedirectUrl) ??
    "https://www.realfagsportalen.no";
  var data = JSON.stringify({
    code,
    redirectUrl,
    state,
  });

  useEffect(() => {
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(`${realfagsportalenUrl}login/after`, options)
      .then((res) => {
        // Have to decode first redirectUrl ? setLoginUrl(redirectUrl) : setLoginUrl(res.data.login_url)
        setLoginUrl(res.data.login_url);
        setPaymentLearnWorldsCourseId(res.data.paymentLearnWorldsCourseId);
        setEmail(res.data.email);
      })
      .catch((err) => {
        const errResponse = err.response;
        if (
          errResponse &&
          errResponse.status === 400 &&
          errResponse.data &&
          errResponse.data.message
        ) {
          setError(errResponse.data.message);
        } else {
          setError(internalServerError);
        }
      });
  }, []);

  useEffect(() => {
    if (loginUrl) {
      if (paymentLearnWorldsCourseId && email) {
        Mixpanel.identify(email);
        Mixpanel.track("Login successful back to payment", { email });
        window.location.href = `${baseUrl}initiate-agreement?email=${email}&coursePath=/course/${paymentLearnWorldsCourseId}`;
      } else {
        Mixpanel.identify(email ?? anonymousUser);
        Mixpanel.track("Login successful", { email });
        window.location.href = loginUrl;
      }
    }
  }, [loginUrl, paymentLearnWorldsCourseId]);

  const informationOnError = (
    <p>
      <p>{error}</p>
      {"Trykk "}
      <u
        onClick={() => setLoginUrl(`https://www.realfagsportalen.no/`)}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til forsiden."}
    </p>
  );
  const displayMessage = error ? informationOnError : <p>{"Logger inn ..."}</p>;
  const isMobile = getIsMobile(window.navigator.userAgent);
  return (
    <div className="App">
      <RealfagsPortalenLogo isMobile={isMobile} />
      {!error && (
        <div style={{ alignItems: "center" }}>
          <MutatingDots
            width="80"
            color="#ffd43b"
            secondaryColor="#ffd43b"
            ariaLabel="three-dots-loading"
          />
        </div>
      )}
      {displayMessage}
    </div>
  );
}

export default AfterLogin;
