import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import VippsButton from "../../../VippsButton";
import PriceBar from "../PriceBar";

interface LoginRequiredCheckoutCardProps {
  price: number;
  discount: number;

  onSubmit: () => void;
}

const LoginRequiredCheckoutCard: React.FC<LoginRequiredCheckoutCardProps> = ({
  price,
  discount,
  onSubmit,
}) => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "100%",
        borderRadius: 2,
        backgroundColor: "#F0F1F3",
        padding: 2,
      }}
    >
      <Stack
        direction="column"
        justifyContent={"space-between"}
        height={"100%"}
      >
        <Stack direction={"column"} width="100%" gap={1}>
          <PriceBar text="Pris" price={price} negative={false} />
          {discount > 0 && (
            <PriceBar text="Rabatt" price={discount} negative={true} />
          )}
          <Divider orientation="horizontal" flexItem />
          <PriceBar
            text="Total"
            price={price - (discount ?? 0)}
            negative={false}
          />
        </Stack>
        <Stack direction={"column"} width={"100%"} gap={2}>
          <Typography
            variant={"body2"}
            color={"text.secondary"}
            textAlign={"center"}
          >
            For å fullføre kjøpet må du først logge inn:
          </Typography>
          <Stack direction={"row"} justifyContent={"center"} width={"100%"}>
            <VippsButton customText={"Logg inn med"} onClick={onSubmit} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoginRequiredCheckoutCard;
