import axios, { AxiosRequestConfig } from "axios";
import { realfagsportalenUrl } from "../initiatePayment";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { stopAgreement } from "./helpers";

interface AgreementViewProps {}

type AgreementStatus = "PENDING" | "ACTIVE" | "STOPPED" | "EXPIRED";

type UserAgreement = {
  id: string;
  status: AgreementStatus;
  start: string;
  productName: string;
  interval: {
    count: number;
    unit: "MONTH" | "WEEK";
  };
  userId: string;
  userName: string;
  courseId: string;
  courseName: string;
};

const findEndDate = (start: string) => {
  const today = new Date();
  const startDate = new Date(start);
  let endDate = new Date();
  const dayOfToday = today.getDate();
  const dayOfStartDate = startDate.getDate();
  if (dayOfToday > dayOfStartDate) {
    endDate.setDate(startDate.getDate());
    endDate.setMonth(today.getMonth() + 1);
  } else {
    endDate.setDate(startDate.getDate());
    endDate.setMonth(today.getMonth());
  }
  return endDate;
};

const AgreementView: React.FC<AgreementViewProps> = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [agreements, setAgreements] = useState<UserAgreement[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<UserAgreement | undefined>(
    undefined
  );

  const userId = urlParams.get("userId");

  const data = JSON.stringify({
    status: "ACTIVE",
    userId: userId ?? undefined,
  });

  const options: AxiosRequestConfig = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    data: data,
  };

  useEffect(() => {
    axios(`${realfagsportalenUrl}agreements`, options)
      .then((res) => {
        setAgreements(res.data.agreements);
      })
      .catch(() => {
        setAgreements([]);
      });
  }, []);

  const handleStopAgreement = async (agreementId: string) => {
    stopAgreement(agreementId);
    axios(`${realfagsportalenUrl}agreements`, options)
      .then((res) => {
        setAgreements(res.data.agreements);
      })
      .catch(() => {
        setAgreements([]);
      });
  };

  return (
    <Card>
      <CardContent>
        <Stack>
          <Typography>Dine avtaler:</Typography>
        </Stack>
        <Table>
          <TableHead>
            <TableCell>Bruker</TableCell>
            <TableCell>Kurs</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Varighet</TableCell>
            <TableCell>Startdato</TableCell>
            <TableCell></TableCell>
          </TableHead>
          {agreements.map((agreement) => (
            <TableRow>
              <TableCell>{agreement.userName}</TableCell>
              <TableCell>{agreement.courseName}</TableCell>
              <TableCell>{agreement.status}</TableCell>
              <TableCell>
                {agreement.interval.count + " " + agreement.interval.unit}
              </TableCell>
              <TableCell>{agreement.start.split("T")[0]}</TableCell>
              <TableCell>
                {agreement.status === "ACTIVE" && (
                  <Button
                    onClick={() => {
                      setAgreement(agreement);
                      setDialogOpen(true);
                    }}
                    color="error"
                    variant="contained"
                  >
                    Stopp avtale
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </CardContent>
      {agreement && (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <Stack direction={"column"} padding={2} gap={2}>
            <Typography variant="h6">
              Er du sikker på at du vil stoppe avtalen?
            </Typography>
            <Stack direction="row">
              <Typography>
                {`Denne handlingen kan ikke angres. Du vil ha tilgang til kurset frem til
              avtalen utløper ${
                findEndDate(agreement.start).toISOString().split("T")[0]
              }.`}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              padding={2}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginLeft={-2}
            >
              <Button
                onClick={() => {
                  setDialogOpen(false);
                  setAgreement(undefined);
                }}
                color="primary"
                variant="contained"
              >
                Nei
              </Button>
              <Button
                onClick={() => {
                  if (agreement) {
                    handleStopAgreement(agreement.id);
                  }
                  setDialogOpen(false);
                  setAgreement(undefined);
                }}
                color="error"
                variant="contained"
              >
                Ja
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
    </Card>
  );
};

export default AgreementView;
