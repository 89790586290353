import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import {
  anonymousUser,
  internalServerError,
  realfagsPortalenRecruitedBy,
  realfagsPortalenRedirectUrl,
  shouldConfirmLoginUrl,
} from "../constants";
import { getIsMobile } from "../utils/helpers";
import { Mixpanel } from "../utils/mixpanel";
import "./App.css";
import RealfagsPortalenLogo from "./RealfagsportalenLogo";
import vipps from "./vippsLogo.png";
import { realfagsportalenUrl } from "./initiatePayment";

function BeforeLogin() {
  const [loginUrl, setLoginUrl] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectUrl = urlParams.get("redirectUrl");
  const recruitedBy = urlParams.get("recruitedBy");
  const paymentLearnWorldsCourseId = urlParams.get(
    "paymentLearnWorldsCourseId"
  );
  const [shouldConfirmLogin, setShouldConfirmLogin] = useState<boolean>(
    !!(redirectUrl && redirectUrl.includes(shouldConfirmLoginUrl))
  );
  var data = JSON.stringify({
    recruitedBy,
    paymentLearnWorldsCourseId,
  });

  useEffect(() => {
    Mixpanel.identify(anonymousUser);
    Mixpanel.track("Entered login page", { redirectUrl, recruitedBy });
  }, []);

  useEffect(() => {
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data,
    };
    axios(`${realfagsportalenUrl}login/initiate`, options)
      .then((res) => {
        res.data.url !== loginUrl && setLoginUrl(res.data.url);
      })
      .catch((err) => {
        const errResponse = err.response;
        if (
          errResponse &&
          errResponse.status === 400 &&
          errResponse.data &&
          errResponse.data.message
        ) {
          setError(errResponse.data.message);
        } else {
          setError(internalServerError);
        }
      });
  }, []);

  useEffect(() => {
    if (loginUrl && !shouldConfirmLogin) {
      if (redirectUrl) {
        window.localStorage.setItem(realfagsPortalenRedirectUrl, redirectUrl);
        setShouldConfirmLogin(redirectUrl.includes(shouldConfirmLoginUrl));
      }
      if (recruitedBy) {
        window.localStorage.setItem(realfagsPortalenRecruitedBy, recruitedBy);
      }
      window.location.href = loginUrl;
    }
  }, [loginUrl, shouldConfirmLogin]);

  const informationOnError = (
    <p>
      <p>{error}</p>
      {"Trykk "}
      <u
        onClick={() => {
          window.location.href = `https://www.realfagsportalen.no/`;
        }}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til forsiden."}
    </p>
  );
  const displayMessage = error ? (
    informationOnError
  ) : (
    <p>Sender deg til innlogging ...</p>
  );
  const confirmMessageFirst =
    "For å prøve dette kurset gratis må du først logge inn, logg inn med ";
  const isMobile = getIsMobile(window.navigator.userAgent);
  return (
    <div className="App">
      <RealfagsPortalenLogo isMobile={isMobile} />
      {!shouldConfirmLogin && !error && (
        <div style={{ alignItems: "center" }}>
          <MutatingDots
            width="80"
            color="#ffd43b"
            secondaryColor="#ffd43b"
            ariaLabel="three-dots-loading"
          />
        </div>
      )}
      {shouldConfirmLogin ? (
        <div>
          <p>
            {confirmMessageFirst}
            <img
              src={vipps}
              width="100"
              height="30"
              style={{
                cursor: "pointer",
                color: "blue",
                marginBottom: "-10px",
              }}
              onClick={() => {
                setShouldConfirmLogin(false);
              }}
            />
          </p>
        </div>
      ) : (
        displayMessage
      )}
    </div>
  );
}

export default BeforeLogin;
