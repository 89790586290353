import axios, { AxiosRequestConfig } from "axios";
import { realfagsportalenUrl } from "../initiatePayment";

export async function stopAgreement(agreementId: string) {
  const options: AxiosRequestConfig = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id: agreementId,
    }),
  };
  const res = await axios(
    `${realfagsportalenUrl}agreements/${agreementId}`,
    options
  );
  if (res.status === 200) {
    return true;
  }
  return false;
}
