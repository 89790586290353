import { Stack } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import {
  anonymousUser,
  internalServerError,
  realfagsPortalenBaseUrl,
  realfagsPortalenIntegrationBaseUrl,
} from "../constants";
import { getIsMobile } from "../utils/helpers";
import { Mixpanel } from "../utils/mixpanel";
import "./App.css";
import CheckoutPage from "./CheckoutPage";
import RealfagsPortalenLogo from "./RealfagsportalenLogo";
import vipps from "./vippsLogo.png";
import { Price } from "./AgreementCheckoutPage";

export interface GetPriceRequest {
  learnWorldsCourseId: string;
  email: string;
  discountCode?: string;
}

export type DiscountCodeStatus =
  | "VALID"
  | "INVALID"
  | "NOT_INCLUDED"
  | "ALREADY_RECRUITED";

export interface GetPriceResponse {
  price: Price;
}

export const realfagsportalenUrl =
  "https://realfagsportalen-api-srzpr47s3q-lz.a.run.app/";

export const getCoursePriceAndDiscount = async (
  learnWorldsCourseId: string,
  email?: string,
  discountCode?: string
): Promise<GetPriceResponse> => {
  const response = await fetch(
    `${realfagsportalenUrl}courses/agreement-price`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        learnWorldsCourseId,
        email,
        discountCode: discountCode ? discountCode.toUpperCase() : undefined,
      } as GetPriceRequest),
    }
  );
  const json: GetPriceResponse = await response.json();
  return json;
};

function InitiatePayment() {
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loginRequired, setLoginRequired] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [discountCode, setDiscountCode] = useState<string>();
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const coursePath = urlParams.get("coursePath");
  const courseIds = coursePath?.split("course/");
  const courseId =
    courseIds?.length === 2 ? courseIds[1] : "Course id not found";
  const email = urlParams.get("email") ?? undefined;
  const isPresent = urlParams.get("isPresent");
  const [price, setPrice] = useState<number>();
  const [discount, setDiscount] = useState<number>(0);
  const [recruiterDiscount, setRecruiterDiscount] = useState<number>(0);
  const [discountCodeDiscount, setDiscountCodeDiscount] = useState<number>(0);
  const [discountCodeStatus, setDiscountCodeStatus] = useState<
    DiscountCodeStatus | "loading"
  >("NOT_INCLUDED");

  var data = JSON.stringify({
    email,
    courseId,
    phoneNumber,
    discountCode,
  });

  useEffect(() => {
    Mixpanel.identify(email ?? anonymousUser);
    Mixpanel.track("Initiate payment page entered", {
      courseId,
      email,
      isPresent,
    });
  }, []);

  useEffect(() => {
    if (!courseId) {
      return;
    }
    getCoursePriceAndDiscount(courseId, email).then((res) => {
      setPrice(res.price.oneTime.price);
      setDiscount(res.price.oneTime.discount);
    });
  }, []);

  const onDiscountCodeSubmit = async () => {
    const initialRes = await getCoursePriceAndDiscount(
      courseId,
      email,
      discountCode
    );

    const res = initialRes.price.oneTime;
    setPrice(res.price);
    setDiscount(res.discount);
    setRecruiterDiscount(res.recruiterDiscount);
    setDiscountCodeDiscount(res.discountCodeDiscount);
    setDiscountCodeStatus(res.discountCodeStatus);
  };

  useEffect(() => {
    if (!courseId) {
      setError("Mangler kurs id");
      return;
    }
    if (!email && coursePath && !isPresent) {
      setLoginRequired(true);
      setRedirectUrl(realfagsPortalenBaseUrl + coursePath);
      return;
    }
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data,
    };
    if (!paymentConfirmed) {
      return;
    }
    axios(`${realfagsportalenUrl}payment/initiate`, options)
      .then((res) => {
        res.data.url !== paymentUrl && setPaymentUrl(res.data.url);
      })
      .catch((err) => {
        const errResponse = err.response;
        if (
          errResponse &&
          errResponse.status === 400 &&
          errResponse.data &&
          errResponse.data.message
        ) {
          setError(errResponse.data.message);
        } else {
          setError(internalServerError);
        }
      });
  }, [paymentConfirmed]);

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);
  const informationOnError = (
    <p>
      <p>{error}</p>
      {"Trykk "}
      <u
        onClick={() => {
          window.location.href = `https://www.realfagsportalen.no/`;
        }}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til forsiden."}
    </p>
  );
  const displayMessage = error ? (
    informationOnError
  ) : (
    <p>Henter kursinformasjon...</p>
  );
  const confirmMessageFirst =
    "For å kjøpe dette kurset må du først logge inn, logg inn med ";
  const isMobile = getIsMobile(window.navigator.userAgent);
  if (loginRequired) {
    return (
      <div className="App">
        <RealfagsPortalenLogo isMobile={isMobile} />
        <div>
          <p>
            {confirmMessageFirst}
            <img
              src={vipps}
              width="100"
              height="30"
              style={{
                cursor: "pointer",
                color: "blue",
                marginBottom: "-10px",
              }}
              onClick={() => {
                window.location.href = `http://localhost:3000?redirectUrl=${redirectUrl}&paymentCourseId=${courseId}`;
              }}
            />
          </p>
        </div>
      </div>
    );
  }
  if (courseId && price && !paymentConfirmed) {
    return (
      <Stack
        width="100%"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#d2cafb",
        }}
      >
        <CheckoutPage
          courseId={courseId}
          onPhoneNumberChange={setPhoneNumber}
          onDiscountCodeChange={setDiscountCode}
          onDiscountCodeSubmit={onDiscountCodeSubmit}
          onSubmit={() => {
            Mixpanel.identify(email ?? anonymousUser);
            Mixpanel.track("Pay with vipps pressed", {
              courseId,
              email,
              phoneNumber,
            });
            setPaymentConfirmed(true);
          }}
          price={price}
          discount={discount}
          recruiterDiscount={recruiterDiscount}
          discountCodeDiscount={discountCodeDiscount}
          discountCodeStatus={discountCodeStatus}
          isOriginallyPresent={!!isPresent}
        />
      </Stack>
    );
  }
  return (
    <div className="App">
      <RealfagsPortalenLogo isMobile={isMobile} />
      {!error && (
        <div style={{ alignItems: "center" }}>
          <MutatingDots
            width="80"
            color="#ffd43b"
            secondaryColor="#ffd43b"
            ariaLabel="three-dots-loading"
          />
        </div>
      )}
      {displayMessage}
    </div>
  );
}

export default InitiatePayment;
