import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { anonymousUser } from "../../constants";
import { courseIdToPrettyCourseId } from "../../utils/coursePrint";
import { getIsMobile } from "../../utils/helpers";
import { Mixpanel } from "../../utils/mixpanel";
import { DiscountCodeStatus } from "../initiatePayment";
import Slider from "../Slider";
import CheckoutMobile from "./CheckoutMobile";
import CheckoutOneTime from "./CheckoutOneTime";
import CheckoutRecurring from "./CheckoutRecurring";

export type Access = "Abonnement" | "Engangskjøp";

interface AgreementCheckoutPageProps {
  courseId: string;
  price: Price;
  discount?: number;
  onSubmit: () => void;
  onAccessChange: (access: Access) => void;
  access: Access;
  email?: string;
}

export type Price = {
  oneTime: {
    price: number;
    discount: number;
    recruiterDiscount: number;
    discountCodeDiscount: number;
    discountCodeStatus: DiscountCodeStatus;
  };
  subscription: {
    recurringPrice: number;
    discountRecurring: number;
    initialPrice: number;
    discountInitial: number;
  };
};

const AgreementCheckoutPage: React.FC<AgreementCheckoutPageProps> = ({
  courseId,
  price,
  onSubmit,
  onAccessChange,
  access,
  email,
}) => {
  const isMobile = getIsMobile(window.navigator.userAgent);

  if (isMobile) {
    return (
      <CheckoutMobile
        price={price.subscription}
        courseId={courseId}
        onSubmit={onSubmit}
        needLogin={!email}
      />
    );
  }

  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "850px",
        paddingX: 0,
      }}
    >
      <CardContent>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ paddingX: 2 }}
        >
          <Stack
            direction="row"
            sx={{
              paddingY: 2,
              alignItems: "center",
              justifyContent: "space-between",
              width: "95%",
            }}
          >
            <Typography variant="h5">Tilgang til kurs: </Typography>
            <Slider
              onValueChange={(newValue: string) => {
                Mixpanel.identify(email || anonymousUser);
                Mixpanel.track("Access changed", {
                  courseId,
                  newAccess: newValue,
                  email,
                });
                onAccessChange(
                  newValue === "Abonnement" ? "Abonnement" : "Engangskjøp"
                );
              }}
              leftValue="Abonnement"
              rightValue="Engangskjøp"
              defaultValue="Abonnement"
            />
          </Stack>
          <Divider
            sx={{ width: "100%", borderWidth: 1.5, alignSelf: "center" }}
          />
          {access === "Abonnement" ? (
            <CheckoutRecurring
              courseId={courseId}
              price={price.subscription}
              onSubmit={onSubmit}
              needLogin={!email}
            />
          ) : (
            <CheckoutOneTime
              courseId={courseId}
              email={email}
              price={price.oneTime.price}
              discount={price.oneTime.discount}
              onSubmit={onSubmit}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AgreementCheckoutPage;
