import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { courseIdToPrettyCourseId } from "../../../utils/coursePrint";
import CheckoutCard from "../CheckoutCard";
import { courseIdToImages } from "../courseIdToImages";
import CheckoutCardOneTime from "../CheckoutCardOneTime";
import {
  DiscountCodeStatus,
  getCoursePriceAndDiscount,
} from "../../initiatePayment";

interface CheckoutOneTimeProps {
  courseId: string;
  email?: string;
  price: number;
  onSubmit: () => void;
  discount?: number;
}

const CheckoutOneTime: React.FC<CheckoutOneTimeProps> = ({
  courseId,
  email,
  price,
  onSubmit,
  discount,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId];
  const [discountCode, setDiscountCode] = useState<string>();
  const [recruiterDiscount, setRecruiterDiscount] = useState<number>(0);
  const [discountCodeDiscount, setDiscountCodeDiscount] = useState<number>(0);
  const [discountCodeStatus, setDiscountCodeStatus] = useState<
    DiscountCodeStatus | "loading" | undefined
  >();

  const onDiscountCodeSubmit = async () => {
    const initialRes = await getCoursePriceAndDiscount(
      courseId,
      email,
      discountCode
    );
    const res = initialRes.price.oneTime;

    setRecruiterDiscount(res.recruiterDiscount);
    setDiscountCodeDiscount(res.discountCodeDiscount);
    setDiscountCodeStatus(res.discountCodeStatus);
  };
  return (
    <Stack direction={"row"} sx={{ width: "100%", minHeight: "330px" }}>
      <Stack direction={"column"} sx={{ width: "50%" }}>
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 180,
              width: 210,
              marginLeft: -3,
              marginTop: -2,
            }}
            src={courseIdToImages[courseId]}
          />
          <Stack direction={"column"} sx={{ width: "60%" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginLeft: -4 }}
            >
              {prettyCourseId}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginLeft: -4 }}
            >{`Ubegrenset tilgang`}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"column"} sx={{ width: "50%", paddingY: 1 }}>
        <CheckoutCardOneTime
          courseId={courseId}
          discount={discount ?? 0}
          recruiterDiscount={recruiterDiscount}
          discountCodeDiscount={discountCodeDiscount}
          discountCodeStatus={discountCodeStatus}
          price={price}
          isPresent={false}
          onSubmit={onSubmit}
          onDiscountCodeChange={setDiscountCode}
          onDiscountCodeSubmit={onDiscountCodeSubmit}
          needLogin={!email}
        />
      </Stack>
    </Stack>
  );
};

export default CheckoutOneTime;
