export const courseIdToPrettyCourseId: Record<string, string> = {
  "matematikk-r1": "Matematikk R1",
  "matematikk-r2": "Matematikk R2",
  "matematikk-s1": "Matematikk S1",
  "matematikk-s2": "Matematikk S2",
  "matematikk-1p": "Matematikk 1P",
  "matematikk-2p": "Matematikk 2P",
  "matematikk-1t": "Matematikk 1T",
  "eksamenskurs-1t": "Eksamenskurs 1T",
  "r1-eksamenskurs": "Eksamenskurs R1",
};
